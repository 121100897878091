<template>
  <div class="user">
    <div class="container">
      <el-form ref="form" :model="queryInfo" label-width="120px">
        <el-card shadow="never">
          <div class="form">
            <div class="comble_txt">
              <el-form-item style="margin: 0 0 12px 0;" label="工具类型:" prop="">
                <el-radio-group v-model="queryInfo.evaluationType" @change="search">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">企业数智化转型诊断评估 </el-radio-button>
                  <el-radio-button label="2">企业数智化转型政策申报 </el-radio-button>
                  <el-radio-button label="3">调查问卷工具</el-radio-button>
                </el-radio-group>
              </el-form-item>
              <!-- <el-form-item label="诊断状态:" prop="">
                <el-radio-group v-model="queryInfo.diagnoseState" @change="search">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="1">已诊断 </el-radio-button>
                  <el-radio-button label="0">未诊断 </el-radio-button>
                </el-radio-group>
              </el-form-item> -->
              <el-form-item style="margin: 0 0 0 0;" label="填写状态:" prop="">
                <el-radio-group v-model="queryInfo.submitState" @change="search">
                  <el-radio-button label="">全部 </el-radio-button>
                  <el-radio-button label="0">已保存 </el-radio-button>
                  <el-radio-button label="1">已提交 </el-radio-button>
                </el-radio-group>
              </el-form-item>
            </div>
          </div>
        </el-card>
      </el-form>
      <el-card shadow="never" style="margin-top: 16px">
        <div class="input-with">
          <el-input
            placeholder="请输入企业名称"
            v-model="queryInfo.companyFullName"
            class="input-with-select"
          >
            <el-button class="seach" slot="append" @click="search()">搜索</el-button>
          </el-input>
          <el-button v-if="$hasPerms('diagnosis:export')" @click="listDiagnoseExport"
            >批量导出</el-button
          >
        </div>
        <el-table
          v-loading="loading"
          element-loading-text="加载中..."
          element-loading-spinner="el-icon-loading"
          :data="listData"
          ref="multipleTable"
          stripe
          style="width: 100%"
          :row-key="getRowKey"
          @sort-change="changeSort"
          @selection-change="handleSelectionChange"
          class="in_table"
          :header-cell-style="{ background: '#F8F9FB', color: '#595959' }"
        >
          <el-table-column type="selection" width="50" align="center" :reserve-selection="true" />
          <el-table-column label="编号" width="50" align="center" type="index"></el-table-column>
          <el-table-column label="企业名称" align="center" min-width="120">
            <template v-slot="{ row }">
              <span>{{ row.companyName }}</span>
            </template>
          </el-table-column>
          <el-table-column
            label="提交保存时间"
            sortable
            align="center"
            min-width="140"
          >
          <template v-slot="{ row }">
          <div>{{ row.createTime | date }}</div>
          <div>{{ row.createTime | time }}</div>
        </template>
        </el-table-column>

          <el-table-column label="填写状态" prop="companyFullName" align="center" min-width="100">
            <template v-slot="{ row }">
              <span v-if="row.submitState == 0">已保存</span>
              <span style="color: #4e93fb" v-if="row.submitState == 1">已提交</span>
            </template>
          </el-table-column>
          <!-- <el-table-column label="诊断状态" prop="content" align="center" min-width="100">
            <template v-slot="{ row }">
              <span style="color: #ff7d18" v-if="row.diagnoseState == 0">未诊断</span>
              <span style="color: #4e93fb" v-if="row.diagnoseState == 1">已诊断</span>
            </template>
          </el-table-column> -->
          <el-table-column
            label="工具名称"
            prop="toolName"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="工具类型" align="center" min-width="120">
            <template v-slot="{ row }">
              <span>
                {{ getEvaluationTypeTxt(row.evaluationType) }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="姓名"
            prop="name"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="职位"
            prop="position"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column label="手机号" align="center" min-width="120">
            <template v-slot="{ row }">
              <span>{{ row.phone }}</span>
            </template>
          </el-table-column>
          <!-- <el-table-column
            label="操作人"

            prop="updateName"
            align="center"
            min-width="100"
          ></el-table-column>
          <el-table-column
            label="操作时间"
            sortable
            prop="updateTime"
            align="center"
            min-width="140"
          ></el-table-column> -->
          <el-table-column
            label="操作"
            align="center"
            class-name="small-padding fixed-width"
            min-width="140"
            v-if="$hasPerms('diagnosis:info')"
          >
            <template slot-scope="scope">
              <el-button style="color: cornflowerblue" type="text" @click="torecord(scope.row)"
                >详情
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.pageNum"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>
  </div>
</template>

  <script>
import { listPage, listDiagnoseExport } from '@/api/servicesmage'
const defaultQueryInfo = Object.freeze({
  orders: 1, //排序 默认传1 2->提交/保存升序;3->提交/保存降序;4->操作时间升序;5->操作时间降序
  pageNum: 1,
  pageSize: 10,
  partnerChannelId: 1, //合作伙伴编号
  diagnoseState: '', //诊断状态（0：未诊断，1：已诊断）
  evaluationType: '', //评估类型(1:企业数智化转型诊断评估,2:企业数据化转型政策申报,3->调查问卷工具)
  submitState: '', //提交状态（0：保存，1：提交）
  companyFullName: '' //企业名称
})
export default {
  name: 'Diagnosisrecord',
  data() {
    return {
      queryInfo: { ...defaultQueryInfo },
      listData: [],
      // 遮罩层
      loading: true,
      // 总条数
      total: 0,
      parteninfo: JSON.parse(sessionStorage.getItem('typeMode')),

      ids: []
    }
  },
  created() {
    this.search()
  },
  // mounted() {
  //   document.querySelector('.el-table__body-wrapper').style.height =
  //     document.body.clientHeight - 164.44 - 40 - 20 - 80 - 59 - 72 - 84 + 'px'
  // },
  methods: {
    //查询一览数据
    async search() {
      this.loading = true
      this.queryInfo.partnerChannelId = this.parteninfo.id
      const { data: res } = await listPage(this.queryInfo)
      if (res.resultCode === 200) {
        this.listData = res.data.list
        this.total = res.data.total
        this.loading = false
      } else {
        this.loading = false
      }
    },
    getEvaluationTypeTxt(val) {
      if (val == '1') {
        return '企业数智化转型诊断评估工具'
      } else if (val == '2') {
        return '企业数智化转型政策申报工具'
      } else {
        return '调查问卷工具'
      }
    },
    //进入诊断详情
    torecord(row) {
      this.$router.push({
        path: '/customer/recordinfo',
        query: {
          id: row.webId,
          diagnoseId: row.id,
          questionnaireId: row.questionnaireId
          // isSelect: row.isSelect  // 是否查询分析报告-已弃用
        }
      })
    },
    //多选
    handleSelectionChange(selection) {
      this.ids = selection.map((item) => item.id)
    },
    getRowKey(row) {
      return row.id
    },
    //排序
    changeSort(val) {
      //val里面返回prop和order
      // 2->创建日期升序;3->创建日期降序;4->登陆次数升序;5->登陆次数降序;6->最近登陆时间升序;7->最近登陆时间降序
      console.log(val)
      if (val.prop == 'createTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时
          this.queryInfo.orders = '2'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '3'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      } else if (val.prop == 'updateTime') {
        if (val.order == 'ascending') {
          //updateAt列升序时

          this.queryInfo.orders = '4'
          this.search()
        } else if (val.order == 'descending') {
          //updateAt列降序时

          this.queryInfo.orders = '5'
          this.search()
        } else {
          //updateAt列不排序时
          this.queryInfo.orders = '1'
          this.search()
        }
      }
    },
    //批量导出
    async listDiagnoseExport() {
      this.loading = true
      let query = {
        orders: this.queryInfo.orders, //排序 默认传1 2->提交/保存升序;3->提交/保存降序;4->操作时间升序;5->操作时间降序
        partnerChannelId: this.queryInfo.partnerChannelId, //合作伙伴编号
        diagnoseState: this.queryInfo.diagnoseState, //诊断状态（0：未诊断，1：已诊断）
        evaluationType: this.queryInfo.evaluationType, //评估类型(1:企业数智化转型诊断评估,2:企业数据化转型政策申报,3->调查问卷工具)
        submitState: this.queryInfo.submitState, //提交状态（0：保存，1：提交）
        companyFullName: this.queryInfo.companyFullName //企业名称
      }
      console.log(query, 'query')
      if (this.ids.length) {
        query['ids'] = this.ids
      }
      const res = await listDiagnoseExport(query)
      if (res.data) {
        this.loading = false
        let blob = new Blob([res.data])
        let href = window.URL.createObjectURL(blob) //创建新的URL表示指定的blob对象
        const a = document.createElement('a') //创建a标签
        a.style.display = 'none'
        console.log(href)
        a.href = href // 指定下载'链接
        let nowdata = new Date()
        let year = nowdata.getFullYear()
        let month = nowdata.getMonth() + 1
        let date = nowdata.getDate()
        let nowtime = year + '-'
        if (month < 10) {
          nowtime += '0'
        }
        nowtime += month + '-'
        if (date < 10) {
          nowtime += '0'
        }
        nowtime += date
        a.download = `诊断记录数据${nowtime}.xlsx` //指定下载文件名
        // a.download = "test.png";
        a.click() //触发下载
        window.URL.revokeObjectURL(a.href) //释放URL对象
        if (document.body.contains(a)) {
          document.body.removeChild(a) //释放标签
        }
        this.search()
        this.$refs.multipleTable.clearSelection()
        this.isIndeterminate = false
        this.checkAll = false
      } else {
        this.$message.error('下载失败!')
      }
    },
    // 分页
    handleSizeChange(val) {
      this.queryInfo.pageSize = val
      this.search()
      // console.log(`每页 ${val} 条`)
    },
    // 分页
    handleCurrentChange(val) {
      this.queryInfo.pageNum = val
      this.search()
      // console.log(`当前页: ${val}`)
    },
    //跳转诊断根据
    diagnosisclick() {
      var url = 'https://tool.elitegomedia.com/information'

      // window.location = url;
      window.open(url, '_blank')
    },
    gototool(row) {
      var url = `https://tool.elitegomedia.com/information/policytools?webId=${row.webId}&id=${
        row.questionnaireId
      }&flag=${1}`

      // window.location = url;
      window.open(url, '_blank')
    }
  }
}
</script>

  <style lang="less" scoped>
.user {
  width: 100%;
  overflow: auto;
  .container {
    height: 80vh;
    overflow: auto;
  }
}
::v-deep .el-radio-button:first-child .el-radio-button__inner {
  border-left: 1px solid #fff;
  border-radius: 4px;
  box-shadow: none !important;
}
::v-deep .el-radio-button:last-child .el-radio-button__inner {
    border-radius: 4px;
}
/deep/ .el-radio-button__inner {
  border: none;
  margin-right: 12px;
  border-radius: 4px;
}
.span_div {
  font-size: 14px;
  color: #7c7f8e;
  margin-right: 12px;
}

.input-with {
  width: 100%;
  overflow: hidden;

  .input-with-select {
    width: 400px;
    float: right;
  }

  .seach {
    background-color: #448aff;
    color: #fff;
    border-radius: 0;
    border: 1px solid #448aff;
  }
}

.in_table {
  width: 100%;
  margin-top: 20px;

  .img_url {
    width: 42px;
    height: 42px;
    border-radius: 4px;
  }
}

.new_page {
  margin: 20px auto;
  width: 100%;
  text-align: center;
}
.el-card.is-always-shadow {
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  // box-shadow: none;
}
/deep/.el-table__body-wrapper {
  // min-height: calc(100vh - 60px - 40px - 168px - 40px - 40px - 59px - 32px - 40px - 40px);
  overflow: auto;
}
/deep/.el-form-item__label {
  width: 90px !important;
}
/deep/ .el-form-item__label {
  // width: 0px !important;
  text-align: left;
  width: 73px !important;
}
/deep/ .el-form-item__content {
  margin-left: 70px !important;
}
/deep/ .el-card__body{
  padding: 12px 16px 12px 16px;
}
</style>
